<template>
  <div class="emailModal">
    <div class="modal fade" id="modal-email" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg">
        <div class="modal-content" v-if="email">
          <div
            class="modal-header d-flex justify-space-between align-items-center"
          >
            <h5 class="mb-0 text-primary client-tab-heading">
              {{ email.subject }}
            </h5>
            <span>
              <small>{{ email.sent_date | formatDate }}</small>
            </span>
          </div>

          <!-- Modal Body -->
          <div
            class="modal-body bg-white"
            style="max-height: 75vh; overflow: scroll"
          >
            <!--  -->
            <div class="row">
              <div class="col">
                <div v-if="showReply">
                  <div class="card bg-light mb-3">
                    <div class="card-body">
                      <div class="row mb-4">
                        <div class="col-12">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Email Subject"
                            v-model="reply.subject"
                          />
                        </div>
                      </div>
                      <!--  -->
                      <div class="row mb-3">
                        <div class="col-12">
                          <vue-editor
                            class="email-template-editor bg-white"
                            v-model="reply.message"
                            required
                          ></vue-editor>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col text-end">
                          <button
                            class="btn btn-primary"
                            :disabled="sending"
                            @click="sendReply"
                          >
                            <i class="far fa-paper-plane me-1"></i>
                            Send Reply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-html="email.mailBody"></div>
              </div>
            </div>
            <!--  -->
          </div>

          <!-- Modal Actions -->
          <div class="modal-footer py-2 bg-white">
            <button
              type="button"
              class="btn btn-outline-secondary me-auto"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
            <button
              class="btn btn-outline-primary float-end me-2"
              v-if="email.type !== 'from'"
              @click="reSend"
            >
              Re-send Email
            </button>
            <button
              class="btn btn-primary float-end"
              v-if="!showReply"
              @click="showReplyOptions"
            >
              Reply to Email
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  props: ["email", "user", "client"],
  data() {
    return {
      showReply: false,
      reply: {
        subject: "",
        message: "",
      },
      sending: false,
    };
  },
  methods: {
    showReplyOptions() {
      this.showReply = true;
      this.reply.subject = "Re: " + this.email.subject;
    },
    closeModal() {
      this.reply = {
        subject: "",
        message: "",
      };
      this.sending = false;
      this.showReply = false;
    },
    sendReply() {
      this.sending = true;

      var formData = new FormData();
      formData.append("subject", this.reply.subject);
      formData.append("message", this.reply.message);
      formData.append("client_id", this.client.id);

      this.$axios
        .post(process.env.VUE_APP_API_URL + "/clients/send-email", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$emit("updated");
          this.closeModal();
        });
    },
    reSend() {
      var confirmed = confirm(
        "Are you sure? This will re-issue the communication to the client."
      );

      if (confirmed) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/clients/re-send-email", {
            email_id: this.email.id,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$emit("updated");
            this.closeModal();
          });
      }
    },
  },
  created() {},
  mounted() {
    console.log("mounted");
    $("#modal-email").modal("show");

    $("#modal-email").on("hide.bs.modal", (e) => {
      this.closeModal();
    });
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
  },
  components: { VueEditor },
};
</script>

<style>
</style>
