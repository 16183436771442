<template>
  <div class="container">
    <div v-if="client">
      <div class="emailsView">
        <div class="row mb-3">
          <div class="col-auto my-auto">
            <h5 class="mb-0 client-tab-heading">Emails</h5>
          </div>
          <div class="col text-end">
            <button
              v-if="!client.welcome_email_sent"
              class="btn btn-outline-primary btn-sm me-2"
              @click="showWelcomeEmailModal"
            >
              <i class="far fa-hand-sparkles me-1"></i>
              Send Welcome Email
            </button>
            <button
              class="btn btn-outline-primary btn-sm"
              @click="openNewEmailWindow"
            >
              <i class="far fa-plus me-2"></i>Send Client Email
            </button>
          </div>
        </div>

        <!--  -->
        <hr class="my-3" />

        <email-inbox :contact="client" />

        <welcome-email-modal
          :user="user"
          :client="client"
        ></welcome-email-modal>
        <email-modal
          v-if="email"
          :user="user"
          :email="email"
          :client="client"
          @closed="closeEmail"
        ></email-modal>
        <send-email :client="client"></send-email>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import EmailModal from "./email-partials/EmailModal";
import SendEmail from "./email-partials/SendEmail";
import WelcomeEmailModal from "./email-partials/WelcomeEmailModal";
import EmailInbox from "../../../components/inbox/EmailInbox";
export default {
  data() {
    return {
      client: null,
      loading: true,
      email: null,
      emails: [],
      filteredEmails: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    sortEmailsBy(sort) {
      if (sort == "oldest-newest") {
        this.emails.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else if (sort == "newset-oldest") {
        this.emails.sort(
          (b, a) => new Date(a.created_at) - new Date(b.created_at)
        );
      }
    },
    openNewEmailWindow() {
      $("#modal-send-email").modal("show");
    },
    openEmail(email) {
      this.email = email;
      $("#modal-email").modal("show");
    },
    closeEmail(email) {
      this.email = null;
      $("#modal-email").modal("hide");
    },
    filterType(type) {
      this.filteredEmails = this.emails.filter(function (email) {
        return email.type === type;
      });
    },
    showWelcomeEmailModal() {
      $("#welcomeEmailModal").modal("show");
    },
    async fetchClientData() {
      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
    formatDateTime(date) {
      return moment.utc(date).local().format("LLL");
    },
    formatType(type) {
      if (type === "from") {
        return "Incoming";
      } else if (type === "to") {
        return "Outgoing";
      } else if (type === "system") {
        return "Automated System Mail";
      }
    },
  },
  async mounted() {
    this.$stash.clientInView = this.$route.params.id;
    await this.fetchClientData();
  },
  components: {
    EmailInbox,
    WelcomeEmailModal,
    EmailModal,
    SendEmail,
  },
};
</script>

<style>
</style>
