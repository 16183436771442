<template>
  <div class="sendEmailModal">
    <form @submit.prevent="sendEmail">
      <div class="modal fade" id="modal-send-email" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div
              class="
                modal-header
                d-flex
                justify-space-between
                align-items-center
              "
            >
              <h5 class="mb-0 text-primary client-tab-heading">
                Send Client Email
              </h5>
            </div>

            <!-- Modal Body -->
            <div
              class="modal-body bg-white"
              style="max-height: 75vh; overflow: scroll"
            >
              <busy :visible="loading" />
              <div v-if="!loading">
                <div v-if="!type">
                  <div class="card bg-light">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <div
                            class="card shadow-sm cursor-pointer"
                            @click="type = 'template'"
                          >
                            <div class="card-body text-center">
                              <i
                                class="far fa-mail-bulk fa-3x text-primary mb-3"
                              ></i>
                              <h5>Choose from Templates</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div
                            class="card shadow-sm cursor-pointer"
                            @click="type = 'compose'"
                          >
                            <div class="card-body text-center">
                              <i class="far fa-pen fa-3x text-primary mb-3"></i>
                              <h5>Compose New Email</h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col-auto mx-auto">
                          <div
                            class="card shadow-sm cursor-pointer"
                            @click="type = 'series'"
                          >
                            <div class="card-body text-center">
                              <i
                                class="far fa-list fa-3x text-primary mb-3"
                              ></i>
                              <h5>Send Email Series</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div v-if="type === 'template'">
                    <div class="row mb-3">
                      <div class="col-6">
                        <label>Email Template</label>
                        <select
                          class="form-control"
                          v-model="emailTemplate"
                          required
                        >
                          <option :value="null" selected disabled>
                            Select an Email Template
                          </option>
                          <option
                            v-for="template in emailTemplates"
                            :value="template.id"
                          >
                            {{ template.title }}
                          </option>
                        </select>
                      </div>
                      <div class="col-6">
                        <label>Send Date</label>
                        <utc-flat-pickr v-model="sendDate" :req="true">
                        </utc-flat-pickr>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="type === 'compose'">
                    <div class="row mb-4">
                      <div class="col-12">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Email Subject"
                          v-model="subject"
                          required
                        />
                      </div>
                    </div>
                    <!--  -->
                    <div class="row mb-3">
                      <div class="col-12">
                        <vue-editor
                          class="email-template-editor"
                          v-model="message"
                          required
                        ></vue-editor>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="type === 'series'">
                    <busy :visible="seriesBusy"
                      >Setting up email series...</busy
                    >
                    <div v-if="!seriesBusy" class="row mb-3">
                      <div class="col-6">
                        <label>Email Series</label>
                        <select
                          class="form-control"
                          v-model="selectedEmailSeries"
                          required
                        >
                          <option :value="null" selected disabled>
                            Select an Email Series
                          </option>
                          <option
                            v-for="series in emailSeries"
                            :value="series.id"
                          >
                            {{ series.title }}
                          </option>
                        </select>
                      </div>
                      <div class="col-6">
                        <label>Send Date</label>
                        <utc-flat-pickr v-model="seriesSendDate" :req="true">
                        </utc-flat-pickr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal Actions -->
            <div class="modal-footer py-2 bg-white" v-if="type">
              <button
                type="button"
                class="btn btn-outline-secondary"
                ref="close"
                data-bs-dismiss="modal" data-dismiss="modal"
                aria-label="Close"
                @click="type = ''"
                :disabled="seriesBusy"
              >
                Cancel
              </button>
              <button
                class="btn btn-primary ms-auto"
                type="submit"
                :disabled="seriesBusy"
              >
                Send Email
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { TextEditor } from "@jaythegeek/crmtoolkit";
import UtcFlatPickr from "../../../../globals/UtcFlatPickr";
import mimetext from "mimetext";

export default {
  props: ["client"],
  data() {
    return {
      loading: true,
      type: "",
      subject: "",
      message: "",
      updatedContent: "",
      emailTemplates: [],
      emailTemplate: null,
      sendDate: moment(),

      //
      selectedEmailSeries: null,
      emailSeries: [],
      seriesSendDate: moment(),
      seriesBusy: false,

      emailIntegrationConfig: null,
    };
  },
  mounted() {
    this.fetchEmailTemplates();
    this.fetchEmailSeries();
    this.getEmailIntegrationConfig();
  },
  methods: {
    sendEmail() {
      if (this.type === "template") {
        this.sendEmailTemplate();
      } else if (this.type === "compose") {
        this.sendNewEmail();
      } else if (this.type === "series") {
        this.sendEmailSeries();
      }
    },
    sendEmailTemplate() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/clients/send-email-template", {
          client_id: this.client.id,
          email_template_id: this.emailTemplate,
          send_date: this.sendDate,
        })
        .then(({ data }) => {
          this.$emit("updated");
          this.$EventBus.$emit("alert", data);
          this.message = "";
          this.subject = "";
          this.$refs.close.click();
        });
    },
    sendEmailSeries() {
      this.seriesBusy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/clients/send-email-series", {
          client_id: this.client.id,
          email_series_id: this.selectedEmailSeries,
          send_date: this.seriesSendDate,
        })
        .then(({ data }) => {
          this.$emit("updated");
          this.$EventBus.$emit("alert", data);
          this.message = "";
          this.subject = "";
          this.seriesBusy = false;
          this.$refs.close.click();
        });
    },

    sendNewEmail() {
      if (this.$stash.gmail.signedIn) {
        const message = mimetext;

        message.setSender({
          name: this.$store.getters['auth/user'].name,
          addr: this.$stash.gmail.emailAddress,
        });
        message.setRecipient(this.client.email);

        message.setSubject(this.subject);
        message.setMessage(this.message);

        let sendRequest = this.$google.api.client.gmail.users.messages.send({
          userId: "me",
          resource: {
            raw: message.asEncoded(),
          },
        });

        sendRequest.execute(({ response }) => {
          this.$emit("updated");
          this.$EventBus.$emit("alert", { message: "Sent" });
          this.message = "";
          this.subject = "";
          this.$refs.close.click();
        });
      } else {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/clients/send-email", {
            client_id: this.client.id,
            subject: this.subject,
            message: this.message,
            mail_config_id: this.emailIntegrationConfig
              ? this.emailIntegrationConfig.id
              : null,
          })
          .then(({ data }) => {
            this.$emit("updated");
            this.$EventBus.$emit("alert", data);
            this.message = "";
            this.subject = "";
            this.$refs.close.click();
          });
      }
    },
    fetchEmailTemplates() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/email-templates/api")
        .then(({ data }) => {
          this.emailTemplates = data.filter(function (template) {
            return template.type === "client";
          });
        });
    },
    fetchEmailSeries() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/email-series/api")
        .then(({ data }) => {
          // this.emailSeries = data.filter(function (series) {
          //   return series.type === "series";
          // });

          this.emailSeries = data;
        });
    },
    getEmailIntegrationConfig() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/practitioners/fetch-email-integration",
          {}
        )
        .then(({ data }) => {
          this.emailIntegrationConfig = data;
          this.loading = false;
        });
    },
  },
  components: {
    UtcFlatPickr,
    TextEditor,
  },
};
</script>
