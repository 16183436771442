<template>
  <div
    class="modal fade"
    id="welcomeEmailModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="expandedTaskModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title client-tab-heading"
            id="expandedTaskModalTitle"
          >
            Send Welcome Email
          </h5>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col">
              <label>Welcome Email Template</label>
              <select
                class="form-control"
                v-model="
                  user.marketing_email_settings.welcome_email_template_id
                "
              >
                <option :value="null" selected disabled>
                  Select an Email Template (Type: Client)
                </option>
                <option v-for="template in emailTemplates" :value="template.id">
                  {{ template.title }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="sendWelcomeEmail"
          >
            Send Welcome Email
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "client"],
  data() {
    return {
      emailTemplates: [],
    };
  },
  methods: {
    fetchEmailTemplates() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/email-templates/api")
        .then(({ data }) => {
          this.emailTemplates = data.filter(function (template) {
            return template.type === "client";
          });
        });
    },
    sendWelcomeEmail() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/send-welcome-email",
          {
            welcome_email_template_id:
              this.user.marketing_email_settings.welcome_email_template_id,
          }
        )
        .then(({ data }) => {
          this.client.welcome_email_sent = true;
          this.$EventBus.$emit("alert", data);
          $("#welcomeEmailModal").modal("hide");
        });
    },
  },
  created() {},
  mounted() {
    this.fetchEmailTemplates();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
  },
  components: {},
};
</script>

<style>
</style>
