<template>
  <div>

    <compose-email-modal v-if="showCompose" />

    <email-view v-if="viewingThread"
                :thread="viewingThread"
                @read="viewingThread.read = true"
                @closed="viewingThread = null" />

    <div v-else>
      <h4 class="fw-bold text-primary">Emails</h4>
      <div class="row">
        <div class="col-lg-2 pe-0">
          <div class="card bg-light border-0 shadow-none h-100 mb-3">
            <div class="card-body px-2">
              <div>
            <button v-if="!contact" class="btn text-start mb-1 w-100 bg-white btn-outline-primary" @click="showCompose=true">
              <i class="far fa-paper-plane me-1" />
              New Email
            </button>
            <button class="btn text-start mb-1 w-100"
                    @click="selectedInbox='inbox'"
                    :class="selectedInbox==='inbox' ? 'btn-primary fst-italic' : 'bg-white'">
              <i class="far fa-inbox-in me-1" />
              Inbox
            </button>
            <button class="btn text-start mb-1 w-100"
                    @click="selectedInbox='sent'"
                    :class="selectedInbox==='sent' ? 'btn-primary fst-italic' : 'bg-white'">
              <i class="far fa-share me-1" />
              Sent
            </button>
            <button class="btn text-start mb-1 w-100"
                    @click="selectedInbox='unread'"
                    :class="selectedInbox==='unread' ? 'btn-primary fst-italic' : 'bg-white'">
              <i class="far fa-exclamation-circle me-1" />
              Unread
            </button>
            <button class="btn text-start mb-1 w-100"
                    @click="selectedInbox='starred'"
                    :class="selectedInbox==='starred' ? 'btn-primary fst-italic' : 'bg-white'">
              <i class="far fa-star me-1" />
              Starred
            </button>
            <button class="btn text-start mb-1 w-100" v-if="!contact"
                    @click="selectedInbox='clients'"
                    :class="selectedInbox==='clients' ? 'btn-primary fst-italic' : 'bg-white'">
              <i class="far fa-users me-1" />
              Client Emails
            </button>
            <button class="btn text-start w-100"
                    @click="selectedInbox='internal'"
                    :class="selectedInbox==='internal' ? 'btn-primary fst-italic' : 'bg-white'">
              <i class="far fa-laptop-medical me-1" />
              System Emails
            </button>
          </div>
            </div>
          </div>
        </div>
        <div class="col-lg-10 ps-2">
          <div class="card bg-light border-0 shadow-none h-100 mb-3">
            <div class="card-body">
              <div v-if="!hasIntegratedEmail && ['inbox', 'sent', 'starred', 'unread', 'clients'].includes(selectedInbox)">
                <email-integration class="my-3"/>
              </div>
              <div v-else>
                <div class="row mb-3 justify-content-between">
                  <div class="col my-auto d-flex">
                    <input
                        type="text"
                        v-model="searchStr"
                        class="form-control form-control d-inline"
                        placeholder="Search emails..."
                        @input="performSearch"
                    />

                    <button class="btn btn btn-light d-inline ms-2" @click="clearSearch">
                      <i class="far fa-times"></i>
                    </button>
                  </div>
                </div>

                <div v-if="!loading && threads.length === 0" class="text-center mb-3">
                  <i class="fal fa-search fa-3x moving-gradient-text mb-3" />
                  <h5 class="fw-normal">No emails found.</h5>
                </div>

                <div class="row" v-for="thread in threads" :key="thread.thread_id">
                  <div class="col">
                    <div class="bg-white rounded shadow-sm mb-1 px-4 py-1 cursor-pointer crank-table-row-link">
                      <div class="row">
                        <div @click="viewingThread = thread" class="col col-auto my-auto pe-0">
                          <i class="fa-circle small me-2 align-middle" :class="!thread.read ? 'far text-primary' : 'fal'"/>
                        </div>
                        <div @click="viewingThread = thread" class="col my-auto wrap-ellipses" :class="!thread.read ? 'fw-bold' : ''">
                          <i v-for="contact in thread.contacts"
                             v-tooltip:right="contact.first_name + ' ' + contact.last_name"
                             class="far fa-user text-primary me-2" />
                          <span class="align-middle">{{thread.messages[0].subject}}</span>
                          <i v-if="!thread.messages[0].subject">(No subject)</i>
                          <div class="ms-2 badge btn-light text-dark align-middle" v-if="thread.messages.length > 1">
                            {{thread.messages.length}}
                          </div>
                        </div>
                        <div class="col col-auto text-end my-auto d-none d-md-block">
                        <span v-if="thread.read"
                              @click.stop="markUnread(thread)"
                              class="badge bg-light cursor-pointer text-dark me-2">Mark Unread</span>
                          <i class="fa-star small me-1 cursor-pointer"
                             :class="thread.starred ? 'text-primary fa fas fw-bold' : 'fal'"
                             @click.stop="toggleStarred(thread)"
                          />
                        </div>
                        <div @click="viewingThread = thread" class="col-auto text-end my-auto d-none d-md-block">
                          {{thread.messages[thread.messages.length-1].sent_date | formatDate}}
                        </div>
                        <div class="col-auto text-end my-auto d-none d-md-block">
                          <div v-if="thread.source === 'gmail'">
                            <div class="dropdown dropleft">
                              <button class="btn btn-light bg-white border-0 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                                <i class="far fa-ellipsis-v cursor-pointer" />
                              </button>
                              <div class="dropdown-menu shadow" aria-labelledby="dropdownMenuButton">
                            <span class="dropdown-item" @click="trashThread(thread)">
                              <i class="far fa-trash me-1" />
                              Move to Bin
                            </span>
                                <span class="dropdown-item" @click="deleteThread(thread)">
                              <i class="far fa-trash text-danger me-1" />
                              Delete Permanently
                            </span>
                              </div>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <i class="far fa-ellipsis-v text-white py-2" />
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="nextPageToken || (pagination && pagination.next_page_url) || loading">
                  <div class="col my-auto">
                    <button
                      class="btn btn-lg btn-light w-100"
                      @click="fetchEmails"
                    >
                      <busy :visible="loading" button />
                      <span v-if="!loading">more</span>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import EmailView from "@/views/components/inbox/EmailView";
import ComposeEmailModal from "@/views/components/inbox/ComposeEmailModal";
import GoogleSetup from "../../areas/settings/areas/integrations-partials/email-partials/GoogleSetup";
import addrparser from "address-rfc2822";
import EmailIntegration from "../../areas/settings/areas/integrations-partials/EmailIntegration";

export default {
  props: ["contact"],
  data() {
    return {
      selectedInbox: 'inbox',
      loadingClients: true,
      loadingEmails: false,
      viewingThread: null,
      showCompose: false,
      clients: [],
      threads: [],
      pagination: {},
      nextPageToken: "",
      dontAppend: false,
      searchStr: "",
      hiddenSearchStr: ""
    };
  },
  computed: {
    loading() {
      return this.loadingEmails || this.loadingClients || this.$stash.gmail.loading;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    hasIntegratedEmail() {
      return this.$stash.gmail.signedIn || (this.user.email_integration_config && this.user.email_integration_config.status === 'active')
    }
  },
  methods: {
    async fetchLightClients() {
      const data = await this.$axios.get(process.env.VUE_APP_API_URL + '/clients/light')
      this.clients = data.data;
      this.loadingClients = false;
    },
    binaryFindClientByEmail(needle) {
      needle = needle.toLowerCase();

      let low = 0
      let high = this.clients.length-1;

      let checks = 0;
      while (low <= high) {
        checks++;
        const mid = Math.floor((low + high) / 2)
        const guess = this.clients[mid]['LOWER(email)'].toLowerCase()

        const compare = guess.localeCompare(needle);
        if (compare === 0) {
          return this.clients[mid]
        } else if (compare > 0) {
          high = mid - 1
        } else if (compare < 0) {
          low = mid + 1
        }
      }

      return null //if not found
    },
    async fetchGmailEmails() {
      this.loadingEmails = true;
      if (this.$stash.gmail.signedIn) {
        const vm = this;
        let labelIds = [];
        switch (this.selectedInbox) {
          case "starred":
            labelIds = ["STARRED"];
            break;
          case "unread":
            labelIds = ["UNREAD"];
            break;
          case "sent":
            labelIds = ["SENT"];
            break;
        }

        let resultsThisTime = 0;
        let noneLeft = false;

        this.stop = false;
        while (resultsThisTime < 10 && !noneLeft && !this.stop) {
          await this.$google.api.client.request({
            path: 'gmail/v1/users/me/threads',
            method: 'GET',
            params: {
              maxResults: 20,
              format: 'full',
              labelIds: labelIds,
              includeSpamTrash: false,
              pageToken: this.nextPageToken,
              q: this.hiddenSearchStr + " " + this.searchStr + " -(label:CHAT)" + (this.contact ? " " + this.contact.email + " " : "")
            }
          }).then(async (response) => {
            const lastPageToken = this.nextPageToken;
            this.nextPageToken = response.result.nextPageToken;
            if (!this.nextPageToken) {
              noneLeft = true;
            }

            if (!(lastPageToken === this.nextPageToken && !this.dontAppend)) {
              const batch = vm.$google.api.client.newBatch();

              if (response.result.threads) {
                response.result.threads.forEach((item) => {
                  batch.add(vm.$google.api.client.request({
                    path: 'gmail/v1/users/me/threads/' + item.id,
                    format: 'full',
                    method: 'GET',
                  }));
                })


                await batch.then((batchResponse) => {
                  Object.keys(batchResponse.result).forEach(async (key) => {
                    const thread = batchResponse.result[key].result;

                    // Iterate thread messages and find relevant emails
                    let addresses = [];
                    thread.messages.forEach((message) => {
                      const cc = this.formatEmailsAsArray(this.getHeaderVal('Cc', message));
                      const from = this.formatEmailsAsArray(this.getHeaderVal('From', message));
                      const to = this.formatEmailsAsArray(this.getHeaderVal('To', message));
                      addresses = _.union(cc, from, to);
                    })
                    thread.contacts = [];
                    addresses.forEach((address) => {
                      const contact = this.binaryFindClientByEmail(address)
                      if (contact){
                        thread.contacts = _.union(thread.contacts, [contact]);
                      }
                    });

                    let shouldInclude = true;

                    if (this.selectedInbox === 'clients' && this.clients.length > 10) {
                      shouldInclude = (thread.contacts.length > 0);
                    }

                    //if filtering by contacts dont add unless there is some
                    if (shouldInclude) {
                      const formattedThread = {
                        ...thread,
                        thread_id: thread.id,
                        source: 'gmail',
                        read: thread.messages ? !thread.messages[0].labelIds.includes("UNREAD") : false,
                        starred: thread.messages ? thread.messages[0].labelIds.includes("STARRED") : false,
                        messages: thread.messages.map((message) => {
                          const cc = this.formatEmailsAsArray(this.getHeaderVal('Cc', message));
                          const from = this.formatEmailsAsArray(this.getHeaderVal('From', message));
                          const to = this.formatEmailsAsArray(this.getHeaderVal('To', message));

                          return {
                            ...message,
                            subject: this.getHeaderVal('Subject', message),
                            sent_date: this.getHeaderVal('Date', message),
                            body: this.getBody(message),
                            cc: cc,
                            from: from,
                            to: to,
                          }
                        })
                      };
                      if (!this.stop) {
                        this.threads.push(formattedThread);
                      }
                      resultsThisTime++;
                    }

                  });
                  if (!this.stop) {
                    this.threads.sort((a, b) => {
                      return new Date(b.messages[b.messages.length-1].sent_date) - new Date(a.messages[a.messages.length-1].sent_date);
                    })
                  }
                });
              } else {
                noneLeft = true;
              }
            } else {
              noneLeft = true;
            }
          });
        }
      }
      this.loadingEmails = false;
    },




    fetchEmails: debounce(function() {
      if (this.dontAppend) {
        this.threads = [];
        this.dontAppend = false;
      }

      if (this.$stash.gmail.signedIn && this.selectedInbox !== 'internal') {
        this.fetchGmailEmails()
      } else {
        if (this.hasIntegratedEmail || this.selectedInbox === 'internal') {
          if (!(this.pagination.last_page_url && !this.pagination.next_page_url)) {
            const baseUrl = this.contact ? "/clients/" + this.contact.id + "/emails" : "/emails";
            let url = this.pagination.next_page_url ? this.pagination.next_page_url : process.env.VUE_APP_API_URL + baseUrl;
            url = this.selectedInbox ? this.updateQueryStringParameter(url, "inbox", this.selectedInbox) : url;
            url = this.searchStr ? this.updateQueryStringParameter(url, "search", this.searchStr) : url;
            url = this.updateQueryStringParameter(url, "paginate", true);
            this.stop = false;
            if (!this.loadingEmails) {
              this.loadingEmails = true;
              this.$axios.get(url).then(({ data }) => {
                this.pagination = data;
                if (!this.stop) {
                  this.threads = this.dontAppend ? data.data : this.threads.concat(data.data);
                }
                this.loadingEmails = false;
              });
            }
          }

        }
      }
    }, 300),
    toggleStarred(thread) {
      if (thread.source === 'gmail') {
        const newVal = !thread.starred;
        const params = newVal ? {addLabelIds: ["STARRED"]} : {removeLabelIds: ["STARRED"]}
        this.$google.api.client.request({
          path: 'gmail/v1/users/me/threads/' + thread.thread_id + '/modify',
          method: 'POST',
          params: params
        }).then((response) => {
          thread.starred = newVal;
        });
      } else {
        const newVal = !thread.starred;
        thread.messages.forEach((email) => {
          email.starred = newVal;
          this.$axios.put(process.env.VUE_APP_API_URL + '/emails/' + email.id, email);
        })
        thread.starred = newVal;
      }
    },
    markUnread(thread) {
      if (thread.source === 'gmail') {
        this.$google.api.client.request({
          path: 'gmail/v1/users/me/threads/' + thread.thread_id + '/modify',
          method: 'POST',
          params: {
            addLabelIds: ["UNREAD"]
          }
        }).then((response) => {
          thread.read = false;
          thread.unread_count = 0;
        });
      } else {
        thread.messages.forEach((email) => {
          email.read = false;
          this.$axios.put(process.env.VUE_APP_API_URL + '/emails/' + email.id, email);
        })
        thread.read = false;
        thread.unread_count = 0;
      }
    },
    trashThread(thread) {
      const thread_id = thread.thread_id;
      this.$google.api.client.request({
        path: 'gmail/v1/users/me/threads/' + thread.thread_id + '/trash',
        method: 'POST',
      }).then((response) => {
        this.threads = this.threads.filter((item) => item.thread_id !== thread_id);
      });
    },
    deleteThread(thread) {
      const thread_id = thread.thread_id;
      this.$google.api.client.request({
        path: 'gmail/v1/users/me/threads/' + thread.thread_id,
        method: 'DELETE',
      }).then((response) => {
        this.threads = this.threads.filter((item) => item.thread_id !== thread_id);
      });
    },
    updateQueryStringParameter(uri, key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
      } else {
        return uri + separator + key + "=" + value;
      }
    },
    clearSearch() {
      this.nextPageToken = "";
      this.dontAppend = true;
      this.searchStr = "";
      this.fetchEmails();
    },
    performSearch: debounce(function() {
      this.fetchEmails();
    }, 300),
    getHeaderVal(field, message) {
      return message.payload.headers.find((header) => header.name === field) ?
          message.payload.headers.find((header) => header.name === field).value : null;
    },
    formatEmailsAsArray(mimeInput) {
      let ret = [];
      if (mimeInput) {
        addrparser.parse(mimeInput).forEach((val) => {
          ret.push(val.address);
        })
      }
      return ret;
    },
    getBody(message) {
      let encodedBody = typeof message.payload.parts === 'undefined' ? message.payload.body.data : this.getHTMLPart(message.payload.parts);
      encodedBody = encodedBody.replace(/-/g, '+')
          .replace(/_/g, '/')
          .replace(/\s/g, '');
      return decodeURIComponent(escape(atob(encodedBody)));
    },
    getHTMLPart(arr) {
      for(let x = 0; x <= arr.length; x++) {
        if(typeof arr[x].parts === 'undefined') {
          if(arr[x].mimeType === 'text/html') {
            return arr[x].body.data;
          }
        } else {
          return this.getHTMLPart(arr[x].parts);
        }
      }
      return '';
    },
    refresh() {
      this.nextPageToken = null;
      this.fetchEmails()
    }
  },
  watch: {
    searchStr() {
      this.nextPageToken = "";
      this.dontAppend = true;
    },
    selectedInbox() {
      this.stop = true;
      this.threads = [];
      this.pagination = {};
      this.nextPageToken = "";
      this.dontAppend = true;

      if (this.selectedInbox === 'clients') {
        if (this.clients.length > 0) {
          if (this.clients.length < 10) {
            this.hiddenSearchStr = "(";

            for (let i=0; i < this.clients.length; i++) {
              let client = this.clients[i];
              this.hiddenSearchStr = this.hiddenSearchStr + client['LOWER(email)'];
              if (i < this.clients.length-1) {
                this.hiddenSearchStr += " || "
              }
            }
            this.hiddenSearchStr += ")";
          }
          this.fetchEmails();
        }
      } else {
        this.fetchEmails();
      }

    }
  },
  async created() {
    this.fetchLightClients();
    try {
      await this.initGmailSignin();
    } catch (e) {
      console.log(e);
    }
    this.fetchEmails();
  },
  mounted() {
    if (!this.hasIntegratedEmail) {
      this.selectedInbox = 'internal';
    }
    this.$EventBus.$on('updatedEmails', this.fetchEmails);

    window.onscroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
      if (bottomOfWindow) {
        this.fetchEmails();
      }
    }
  },
  destroyed() {
    this.$EventBus.$off('updatedEmails', this.fetchEmails);
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
  },
  components: {
    EmailIntegration,
    GoogleSetup,
    ComposeEmailModal,
    EmailView,
  },
};
</script>

<style scoped>
.dropdown-toggle::before{
  display: none !important;
}
</style>
